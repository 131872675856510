import {
  Flex,
  Stack,
  Text,
  ChakraProvider,
  Container,
  Card,
} from '@chakra-ui/react';
import theme from '../../theme';

const FontColor = '#2b3674';

const SurveySuccess = () => {
  return (
    <ChakraProvider theme={theme}>
      <Container
        centerContent={true}
        maxW="80vw"
        minH="100vh"
        height="100%"
        bg="#f4f7fe"
        color={FontColor}
        borderRadius="lg"
        mt="5px"
      >
        <Flex minH={'100vh'} align={'center'} justify={'center'}>
          <Card
            minW="60vw"
            maxW="100%"
            h="200px"
            fontSize="1rem"
            bg="white.500"
            mb="20px"
            padding="5px"
            borderRadius="15px"
            color={FontColor}
          >
            <Stack justifyContent={'center'} alignItems={'center'} mt="75px">
              <Text fontSize="md" fontWeight="bold">
                ¡Gracias por tu tiempo!
              </Text>
              <Text fontSize="md" fontWeight="bold">
                Tus respuestas nos ayudarán a seguir mejorando nuestro servicio
              </Text>
            </Stack>
          </Card>
        </Flex>
      </Container>
    </ChakraProvider>
  );
};

export default SurveySuccess;
