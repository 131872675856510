import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    borderColor: '#323c78', // change the border color
    color: '#323c78',
  },
});

// define custom variant
const variants = {
  survey: definePartsStyle({
    control: {
      borderRadius: 100,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#323c78',
      background: 'white.500',

      _hover: {
        background: '#323c78',
        borderColor: '#323c78',
        color: '#323c78',
      },

      _checked: {
        background: 'white.500',
        borderColor: '#323c78',
        color: '#323c78',

        _hover: {
          background: 'white.500',
          borderColor: '#323c78',
          color: '#323c78',
        },
      },
    },
  }),
};

// export the component theme
export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
});
