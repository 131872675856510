import { useEffect, useReducer, useState } from 'react';
import {
  Card,
  Flex,
  Spinner,
  Stack,
  Text,
  Icon,
  Center,
  Box,
  Container,
  VStack,
  RadioGroup,
  Radio,
  ChakraProvider,
  Textarea,
  Switch,
  FormControl,
  FormLabel,
  Button,
  useMediaQuery,
  FormHelperText,
} from '@chakra-ui/react';
import { get, post } from '../../utils/fetchApi';
import { FiAlertCircle } from 'react-icons/fi';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../config/urls';

type optionType = {
  id: string;
  label: string;
  position?: number;
  value: string;
};

type questionsType = {
  id: string;
  isMandatory: boolean;
  options?: optionType[];
  position?: number;
  questionType: string;
  title: string;
  subtitle?: string;
  placeholder?: string;
};

type ResponseSurvey = {
  uuid: string;
  title: string;
  subtitle?: string;
  notes?: string;
  questions: questionsType[];
};

interface State {
  data?: ResponseSurvey;
  error?: Error;
  status: 'idle' | 'loading' | 'error' | 'fetched';
}

type Action =
  | { type: 'loading' }
  | { type: 'fetched'; payload: ResponseSurvey }
  | { type: 'error'; payload: Error };

const FontColor = '#2b3674';
const DeepBlue = '#2b3674';

const Survey = () => {
  const initialState: State = {
    error: undefined,
    data: undefined,
    status: 'idle',
  };

  const fetchReducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, status: action.type };
      case 'fetched':
        return { ...initialState, data: action.payload, status: action.type };
      case 'error':
        return { ...initialState, error: action.payload, status: action.type };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const [surveyData, setSurveyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');

  const getData = async (uuid: string) => {
    dispatch({ type: 'loading' });
    const url = `${apiUrls.survey}/survey-client/${uuid}`;
    try {
      const response = await get<ResponseSurvey>(url);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const data = response.data as ResponseSurvey;
      dispatch({ type: 'fetched', payload: data });
    } catch (error) {
      dispatch({ type: 'error', payload: error as Error });
    }
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);

  const sendSurvey = async (ev: any, uuid) => {
    ev.preventDefault();
    setIsLoading(true);
    try {
      const url = `${apiUrls.survey}/survey-client/${uuid}`;
      const response = await post<ResponseSurvey>(url, { data: surveyData });
      if (!response.ok) {
        setIsLoading(false);
        navigate('/survey-error');
        return;
      }
      setIsLoading(false);
      navigate('/survey-success');
      return;
    } catch (error) {
      setIsLoading(false);
      navigate('/survey-error');
      return;
    }
  };

  const getComponentByType = (question: questionsType): React.ReactNode => {
    const marginLeft = '20px';
    const marginRight = '20px';
    const marginTop = '15px';
    switch (question.questionType) {
      case 'OPTIONS':
        return (
          <RadioGroup
            className="options-radiogroup"
            key={question.id}
            name={question.id}
            display="flex"
            onChange={(value) =>
              setSurveyData({ ...surveyData, [question.id]: value })
            }
          >
            <Stack
              spacing="40px"
              ml={marginLeft}
              mr={marginRight}
              mt={marginTop}
              direction={isSmallScreen ? 'column' : 'row'}
            >
              {question.options.map((op) => (
                <Stack
                  key={`question-${question.id}-${op.id}`}
                  alignContent="center"
                  direction={isSmallScreen ? 'row' : 'column'}
                >
                  <Radio
                    key={`${question.id}-${op.id}`}
                    value={op.value}
                    variant="survey"
                  />
                  <Box
                    alignSelf="center"
                    color={FontColor}
                    wordBreak="break-word"
                  >
                    {op.label}
                  </Box>
                </Stack>
              ))}
            </Stack>
          </RadioGroup>
        );
      case 'TEXT':
        return (
          <Stack
            spacing="1px"
            ml={marginLeft}
            mr={marginRight}
            mt={marginTop}
            direction="column"
          >
            <Textarea
              key={question.id}
              name={question.id}
              placeholder={question.placeholder}
              resize="none"
              width="90%"
              height={isSmallScreen ? '250px' : '100px'}
              ml={isSmallScreen ? '5px' : marginLeft}
              mt={marginTop}
              maxLength={250}
              onChange={(ev) =>
                setSurveyData({ ...surveyData, [question.id]: ev.target.value })
              }
            />
            <Box
              alignSelf="end"
              color={FontColor}
              mr={isSmallScreen ? '20px' : '70px'}
            >
              {Number(250) - Number(surveyData[question.id]?.length ?? 0)}
            </Box>
          </Stack>
        );
      case 'BOOLEAN':
        return (
          <Switch
            key={question.id}
            name={question.id}
            size="md"
            ml={marginLeft}
            mr={marginRight}
            mt={marginTop}
            defaultValue="1"
            sx={{
              'span.chakra-switch__track[data-checked]': {
                backgroundColor: DeepBlue,
              },
            }}
            onChange={(ev) =>
              setSurveyData({ ...surveyData, [question.id]: ev.target.checked })
            }
          />
        );
      default:
        return null;
    }
  };

  if (state.status === 'error') {
    return (
      <Flex minH={'100vh'} align={'center'} justify={'center'}>
        <Stack justifyContent={'center'} alignItems={'center'} mt={8}>
          <Icon as={FiAlertCircle} color="red.500" w={12} h={12} />
          {state.error?.message ? (
            <Text fontSize="xl" fontWeight="bold">
              {state.error.message}
            </Text>
          ) : (
            <Text fontSize="xl" fontWeight="bold">
              Ups! Algo salió mal
            </Text>
          )}
        </Stack>
      </Flex>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      {state.status === 'loading' ? (
        <Flex minH={'100vh'} align={'center'} justify={'center'}>
          <Stack justifyContent={'center'} alignItems={'center'} mt={8}>
            <Stack justifyContent={'center'} alignItems={'center'} w="100%">
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="cyan.500"
                size="lg"
              />
            </Stack>
          </Stack>
        </Flex>
      ) : (
        <Container
          centerContent={true}
          maxW={isSmallScreen ? '100vw' : '80vw'}
          minH="100vh"
          height="100%"
          bg="#f4f7fe"
          color={FontColor}
          borderRadius="lg"
          mt="5px"
          display="block"
        >
          <VStack spacing="10">
            <Box mt="10px" bg="transparent" border="none">
              <Text color={FontColor} fontSize="4xl" fontWeight="bold">
                {state.data?.title}
              </Text>
            </Box>
            <Card
              minW="60vw"
              maxW="100%"
              fontSize="1.1rem"
              bg="white.500"
              mb="20px"
              padding="5px"
              borderRadius="15px"
            >
              <form onSubmit={(ev) => sendSurvey(ev, id)}>
                <VStack spacing="10" alignItems={'flex-start'} margin="20px">
                  <Text color={FontColor}>{state.data?.subtitle}</Text>
                  {state.data?.questions.map((qq, index) => (
                    <Box
                      key={`box-${qq.id}`}
                      className="question-box"
                      minW="85%"
                      maxW="100%"
                    >
                      <FormControl isRequired={qq.isMandatory}>
                        <FormLabel
                          color={FontColor}
                          className="question-title"
                          fontSize="1.1rem"
                        >
                          {`${index + 1}. ${qq.title}`}
                        </FormLabel>
                        {qq.subtitle && (
                          <FormHelperText
                            className="question-subtitle"
                            fontSize="1rem"
                          >
                            {qq.subtitle}
                          </FormHelperText>
                        )}
                        <Box className="question-content">
                          {getComponentByType(qq)}
                        </Box>
                      </FormControl>
                    </Box>
                  ))}
                </VStack>
                <Center>
                  <Button
                    h="32px"
                    w="125px"
                    borderRadius="10px"
                    mb="10px"
                    type="submit"
                    colorScheme="blue"
                    className="survey-button"
                    bg="#4318ff"
                    _hover={{ bg: '#260e96' }}
                    isLoading={isLoading}
                  >
                    Enviar
                  </Button>
                </Center>
              </form>
            </Card>
          </VStack>
        </Container>
      )}
    </ChakraProvider>
  );
};

export default Survey;
