import { Flex, Stack, Text, Icon } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';

const SurveyError = () => {
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack justifyContent={'center'} alignItems={'center'} mt={8}>
        <Icon as={FiAlertCircle} color="red.500" w={12} h={12} />
        <Text fontSize="xl" fontWeight="bold">
          Ups! Algo salió mal
        </Text>
      </Stack>
    </Flex>
  );
};

export default SurveyError;
