import { AUTH_TOKEN_KEY } from '../hooks/useAuthContext';

interface ErrorType {
  businessMessage?: string;
  cause?: string;
  code?: string;
  exception?: string;
  path?: string;
  user?: string;
}

interface HttpResponse<T> extends Response {
  data?: T & ErrorType;
}

export const getAccessToken = (
  options: RequestInit,
  storage: Storage = localStorage
) => {
  const accessToken = storage.getItem(AUTH_TOKEN_KEY);
  return {
    ...options,
    headers: {
      ...options.headers,
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
  };
};

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);
  try {
    response.data = await response.json();
  } catch (ex) {}

  if (!response.ok) {
    if (response.data) {
      throw new Error(response.data?.businessMessage, {
        cause: response.data?.code,
      });
    }
    throw new Error(response.status.toString());
  }
  return response;
}

export async function get<T>(
  path: string,
  args: RequestInit = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
): Promise<HttpResponse<T>> {
  args = getAccessToken(args);
  return await http<T>(new Request(path, args));
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  }
): Promise<HttpResponse<T>> {
  args = getAccessToken(args);
  return await http<T>(new Request(path, args));
}

export async function patch<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  }
): Promise<HttpResponse<T>> {
  args = getAccessToken(args);
  return await http<T>(new Request(path, args));
}
